import '../../css/footer.css'
const Footer = () => {
    return ( 
        <div className='footer-container'>
            <h2>Contact</h2>
            <div className='footer-separate'></div>
            <div>
                <p>Adam Paul Sagar</p>
                <a  href='https://wa.me/+447747723453?text=Hello%20%F0%9F%91%8B,%20I%20would%20like%20to%20know%20more%20about%20the%20trip%20to%20Argentina.' target='_blank'><p>+44 7747 723453</p></a>
                <p>adam@supergolftrips.com</p>
            </div>
        </div>
     );
}
 
export default Footer;