import '../../../css/argSection3.css';
import img1 from '../../../source/imgArgentina/9.png'
import img2 from '../../../source/imgArgentina/10.png'


const ArgSection3 = () =>{
    return(
        <div className='arg3-container'>
            <img src={img1} alt='Argentina'/>
            <img src={img2} alt='Argentina'/>
        </div>
    );
};

export default ArgSection3;
