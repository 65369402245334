import '../../../css/argSection2.css';
import img1 from '../../../source/imgArgentina/1.png'
import img2 from '../../../source/imgArgentina/2.png'
import img3 from '../../../source/imgArgentina/3.png'
import img4 from '../../../source/imgArgentina/4.png'
import img5 from '../../../source/imgArgentina/5.png'
import img6 from '../../../source/imgArgentina/6.png'
import img7 from '../../../source/imgArgentina/7.png'
import img8 from '../../../source/imgArgentina/8.png'

const ArgSection2 = () =>{
    return(
        <div className='arg2-container'>
            <img src={img1} alt='Argentina'/>
            <img src={img2} alt='Argentina'/>
            <img src={img3} alt='Argentina'/>
            <img src={img4} alt='Argentina'/>
            <img src={img5} alt='Argentina'/>
            <img src={img6} alt='Argentina'/>
            <img src={img7} alt='Argentina'/>
            <img src={img8} alt='Argentina'/>
        </div>
    );
};

export default ArgSection2;
