import React from 'react';
//ui
import HeaderArg from '../ui/HeaderArg';
import ArgSection1 from '../sections/ArgSections/ArgSection1';
import ArgSection2 from '../sections/ArgSections/ArgSection2';
import ArgSection3 from '../sections/ArgSections/ArgSection3';
import Footer from '../ui/Footer';
const Argentina = () => {
    return(
        <div className='className="w-100 h-100"'>
            <HeaderArg/>
            <ArgSection1/>
            <ArgSection2/>
            <ArgSection3/>
            <Footer/>
        </div>
    );
}

export default Argentina;
