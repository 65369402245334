import '../../../css/argSection1.css'
const ArgSection1 = () =>{
    return(
        <div>
            <div className='arg1-title'>
                <h2>
                    Golf trip of a life time, enjoy the South American sierras mountain 
                    range as you play the best courses of Argentina.
                </h2>
            </div>
            <div className='arg1-contents'>
                <div className='arg1-col'>
                    <h2><span className="fw-bold">7 Days</span> <br/> Golf experience</h2>
                    <div className='arg1-options'>
                        <div>
                            <h3>Professional Player Host </h3>
                            <p>Including long & short game clinics.</p>
                        </div>
                        <div>
                            <h3>5 rounds including golf rounds </h3>
                            <p>Potrerillo de Larreta, El Terron, Valle del Golf, Cordoba Golf Club. 4 day tournament with updated scoring.</p>
                        </div>
                        <div>
                            <h3>Luxury Private Villas on Golf course</h3>
                            <p>Including breakfast & in house chef.</p>
                        </div>
                        <div>
                            <h3>Transport included*</h3>
                            <p>Airport transfer, Golf course transfers.</p>
                        </div>
                    </div>
                </div>
                <div  className='arg1-col'>
                    <h2><span className="fw-bold">Additional <br/> </span>experiences</h2>
                    <div className='arg1-options'>
                        <div>
                            <h3>Welcome pack</h3>
                            <p>Including golfing goodies, putting training aid, Apple Air tag and more.</p>
                        </div>
                        <div>
                            <h3>Berta Experience </h3>
                            <p>Berta is one of the most famous names in Argentinean motorsport.</p>
                        </div>
                        <div>
                            <h3>Guided river tour</h3>
                            <p>Check out the best river spots in Cordoba. </p>
                        </div>
                        <div>
                            <h3>Casino Night</h3>
                            <p>The first casino hotel in the Argentine Republic.</p>
                        </div>
                        <div>
                            <h3>Asado Night</h3>
                            <p>Led by a specialized chef, enjoy the ritual of one of the most typical meals.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='arg1-price'>
                <h2>£ 2.250</h2>
                <p>*Flights not included.</p>
            </div>
        </div>
    );
};

export default ArgSection1;
