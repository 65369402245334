import './css/index.css'
import Argentina from "./components/pages/Argentina";
function App() {
  return (
    <div className="w-100 h-100">
      <Argentina/>
    </div>
  );
}

export default App;
