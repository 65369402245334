import Img from '../../source/imgArgentina/bgArg.png'
import ImgSpain from '../../source/imgArgentina/spain.png';
import ImgDubai from '../../source/imgArgentina/dubai.png';
import ImgScotland from '../../source/imgArgentina/scotland.png';
import ImgBulgaria from '../../source/imgArgentina/bulgaria.png';

const HeaderArg = () => {
    return(
        <div className='  argh-container'  style={{backgroundImage:`url(${Img})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'}}>
            <div className='argh-title' style={{borderLeftWidth:'68px',borderColor:'rgba(38, 40, 3, 0.48)',borderLeftStyle:'solid'}}>
                <p className='text-white' style={{fontWeight:'200',textShadow:'0px 4px 6px rgba(0, 0, 0, 0.55)',paddingLeft:'30px'}} >Argentina<br/><span className='fw-bold'>Golf</span><br/>Experience</p>
            </div>
            <div className='d-flex flex-column justify-content-start   mt-4 argh-trips'>
                <div style={{backgroundColor:'rgba(38, 40, 3, 0.48)',textAlign:'center',marginBottom:'10px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <p className='fs-2 text-white fw-light '>Super <span className='fw-bold'>Golf Trips</span></p>
                </div>
                <div className='argh-countries'>
                <img src={ImgSpain} alt='Spain' className='countries-header'/>
                <img src={ImgDubai} alt='Spain' className='countries-header'/>
                <img src={ImgScotland} alt='Spain' className='countries-header'/>
                <img src={ImgBulgaria} alt='Spain' className='countries-header'/>
                </div>
            </div>
        </div>
    );
}

export default HeaderArg;
